import request from '@/utils/request';
export default {
  checkDrawing: function checkDrawing(params) {
    return request.get("/app/v1/common/dictTree/".concat(params, "/0")).then(function (res) {
      return res;
    });
  },
  designStandard: function designStandard(params) {
    return request.post('/app/v1/SpaceDesignStandard/queryByRoomID', params).then(function (res) {
      return res;
    });
  },
  queryByKeyWord: function queryByKeyWord(params) {
    return request.post('/app/v1/SpaceDesignStandard/queryByKeyWord', params).then(function (res) {
      return res;
    });
  },
  getDic: function getDic(params) {
    return request.get("/app/v1/common/dict/".concat(params)).then(function (res) {
      return res;
    });
  },
  sendParams: function sendParams(params) {
    return request.post('/app/v1/SpaceDesignStandard/insertByRoomID', params).then(function (res) {
      return res;
    });
  },
  getAlldata: function getAlldata(params) {
    return request.post('/app/v1/chk-point/getVersionTree', params).then(function (res) {
      return res;
    });
  }
};